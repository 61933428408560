<template>
  <div>
    <b-modal
      v-model="isModalShow"
      title="Edit Tier"
      hide-footer
      scrollable
      @hide="hide"
    >
      <div>
        <b-row>
          <b-col>
            <InputSelect
              title="Tier"
              text="Tier"
              name="tierId"
              valueField="id"
              textField="name"
              v-bind:options="$store.getters.getTierList"
              v-on:onDataChange="val => (form.id = val)"
              v-model="form.id"
              isRequired
              v-bind:isValidate="$v.form.id.$error"
              :v="$v.form.id"
              ref="tierId"
            />
          </b-col>
        </b-row>
        <div class="pb-2">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-button
                class="bg-color-primary text-white text-center"
                @click="onSubmit"
                >แก้ไขข้อมูล</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "ChatOrderProfileCustomFieldModalDetail",
  components: {
    InputSelect
  },
  props: {
    userGUID: {
      required: false,
      type: String
    },
    getCustomerTierByGUID: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      form: {
        id: 0,
        name: "",
        brandId: this.$route.query.brandID,
        userGUID: this.userGUID
      }
    };
  },
  methods: {
    show(item) {
      this.form = { ...item, userGUID: this.userGUID };
      this.isModalShow = true;
    },
    hide() {
      this.isModalShow = false;
    },
    onSubmit: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.$store.dispatch("setIsLoading", true);
      await this.$axios
        .post(`${this.$chatBaseUrl}/customer/tier/save`, this.form)
        .then(response => response.data)
        .then(res => {
          this.$store.dispatch("setIsLoading", false);
          if (res.result == 1) {
            this.getCustomerTierByGUID();
            this.$store.commit("setMessageGlobalAlert", res.message);
            this.$store.commit("setShowGlobalAlert", true);
          } else {
            this.$store.commit("setMessageGlobalAlertError", res.message);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", err.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
      this.isLoading = false;

      this.hide();
    }
  },
  validations() {
    return {
      form: {
        id: { required, minValue: minValue(1) }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.name-title {
  border: none;
  border-bottom: 2px solid #ebebeb;
  outline: none;
  box-shadow: none;
}

.btn-cancel-edit {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}
</style>
