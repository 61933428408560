<template>
  <div>
    <b-modal
      v-model="isModalShow"
      title="EDIT CUSTOM FIELD"
      hide-footer
      scrollable
      @hide="hide"
    >
      <div>
        <b-row>
          <b-col>
            <InputText
              v-model="form.name"
              textFloat="Name"
              placeholder="Name"
              name="nameCustomField"
              isRequired
              type="text"
              isDisplay
            />
            <InputText
              v-model="form.value"
              textFloat="Value"
              placeholder="Value"
              name="valueCustomField"
              isRequired
              :isValidate="$v.form.value.$error"
              :v="$v.form.value"
              type="text"
            />
          </b-col>
        </b-row>
        <div class="pb-2">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-button
                class="bg-color-primary text-white text-center"
                @click="onSubmit"
                >แก้ไขข้อมูล</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ChatOrderProfileCustomFieldModalDetail",
  components: {
    InputText
  },
  props: {
    userGUID: {
      required: false,
      type: String
    },
    getCustomFieldList: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      form: {
        id: 0,
        value: "",
        name: "",
        type: "",
        userGUID: "",
        variableId: 0
      }
    };
  },
  methods: {
    show(item) {
      this.form = item;
      this.isModalShow = true;
    },
    hide() {
      this.isModalShow = false;
    },
    onSubmit: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.$store.dispatch("setIsLoading", true);
      await this.$axios
        .post(`${this.$chatBaseUrl}/Customer/CustomField/save`, this.form)
        .then(response => response.data)
        .then(res => {
          this.$store.dispatch("setIsLoading", false);
          if (res.result == 1) {
            this.getCustomFieldList();
            this.$store.commit("setMessageGlobalAlert", res.message);
            this.$store.commit("setShowGlobalAlert", true);
          } else {
            this.$store.commit("setMessageGlobalAlertError", res.message);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", err.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
      this.isLoading = false;

      this.hide();
    }
  },
  validations() {
    return {
      form: {
        value: { required }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.name-title {
  border: none;
  border-bottom: 2px solid #ebebeb;
  outline: none;
  box-shadow: none;
}

.btn-cancel-edit {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}
</style>
