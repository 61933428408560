<template>
  <div class="panel px-3">
    <div class="card p-3 mb-0">
      <div class="d-flex justify-content-between mb-1">
        <span class="font-weight-bold mb-1">
          <font-awesome-icon
            :icon="['fas', 'map-marker-alt']"
            class="text-c-red"
          />
          {{ " " }}
          {{ title }}
        </span>
        <a
          v-if="!isEdit && !isOrderSendered"
          href="#"
          @click.prevent="hadleEdit(true)"
          >แก้ไข</a
        >
        <a
          v-if="isEdit && !isOrderSendered"
          href="#"
          @click.prevent="hadleEdit(false)"
          >ยกเลิก</a
        >
      </div>
      <div v-if="addressList.length > 0 && !isOrderSendered" class="pb-3 pt-1">
        <label :for="'selectAddress' + typeAddress" class="mb-1"
          >รายการที่อยู่</label
        >
        <b-form-select
          v-model="addressShippingSelectId"
          :name="'selectAddress' + typeAddress"
          @change="onAddressListSelected"
        >
          <b-form-select-option
            v-for="(address, index) in addressList"
            :value="address.id"
            :key="index"
          >
            {{ address.address }}
            <span v-if="address.road">ถนน {{ address.road }} </span>
            แขวง/ตำบล {{ address.subdistrict }} เขต/อำเภอ
            {{ address.district }}
            {{ address.province }}
            {{ address.zipcode }}
          </b-form-select-option>
        </b-form-select>
      </div>
      <div v-if="!isEdit">
        <template v-if="addressInfo.zipcode">
          <div v-if="typeAddress !== 'shipping'">
            <span
              v-if="
                addressInfo.firstname &&
                  addressInfo.nameTitleId != 0 &&
                  typeAddress !== 'shipping' &&
                  nameTitleArray.length > 1
              "
            >
              {{
                nameTitleArray[
                  nameTitleArray.findIndex(t => t.id == addressInfo.nameTitleId)
                ].name
              }}
            </span>
            {{ addressInfo.firstname }}
            {{ addressInfo.lastname }}
          </div>
          <div>{{ addressInfo.address }}</div>
          <div>
            <span v-if="addressInfo.road">ถนน {{ addressInfo.road }} </span>
            แขวง/ตำบล {{ addressInfo.subdistrict }} เขต/อำเภอ
            {{ addressInfo.district }}
          </div>
          <div>
            {{ addressInfo.province }}
            {{ addressInfo.zipcode }}
          </div>
          <div v-if="typeAddress !== 'shipping'">
            เลขประจำตัวผู้เสียภาษี :
            {{ addressInfo.taxNo || addressInfo.tax || "-" }}
          </div>
          <div v-if="isProfileInfo || isOrderSendered" class="mt-2">
            <div v-if="typeAddress !== 'billing' && sameAddress" class="">
              <p class="mb-0">
                <font-awesome-icon icon="check-circle" class="" />
                <span class="ml-1">ใช้ที่อยู่จัดส่งเป็นที่อยู่ใบเสร็จ</span>
              </p>
            </div>

            <div
              v-if="typeAddress !== 'billing' && isRequestTaxInvoice"
              class="mt-0"
            >
              <p class="mb-0">
                <font-awesome-icon icon="check-circle" class="" />
                <span class="ml-1">ออกใบกำกับภาษี</span>
              </p>
            </div>
          </div>
          <div v-if="typeAddress == 'shipping' && isOrderSendered" class="pt-2">
            <span>ข้อความเพิ่มติม : </span>
            {{ addressInfo.shippingNote || "-" }}
          </div>
        </template>
        <template v-else>
          <div class="p-3 m-auto">
            <span>-</span>
          </div>
        </template>
      </div>
      <div v-else class="panel-address">
        <b-row>
          <b-col md="4" v-if="typeAddress !== 'shipping'">
            <InputSelect
              title="คำนำหน้าชื่อ"
              text="คำนำหน้าชื่อ"
              name="nameTitleId"
              valueField="id"
              textField="name"
              size="lg"
              v-bind:options="nameTitleArray"
              v-on:onDataChange="val => (form.nameTitleId = val)"
              v-model="form.nameTitleId"
              isRequired
              v-bind:isValidate="$v.form.nameTitleId.$error"
              :v="$v.form.nameTitleId"
              ref="nameTitleId"
              class="f-regular"
            />
          </b-col>
          <b-col md="4" v-if="typeAddress !== 'shipping'">
            <InputText
              v-model="form.firstname"
              :textFloat="titleFirstname"
              :placeholder="titleFirstname"
              type="text"
              name="nameOrCompanyName"
              size="lg"
              isRequired
              :isValidate="$v.form.firstname.$error"
              :v="$v.form.firstname"
              class="f-regular"
              ref="firstname"
            />
          </b-col>

          <b-col md="4" v-if="typeAddress !== 'shipping'">
            <InputText
              v-model="form.lastname"
              :textFloat="titleLastname"
              :placeholder="titleLastname"
              type="text"
              name="lastname"
              size="lg"
              isRequired
              :isValidate="$v.form.lastname.$error"
              :v="$v.form.lastname"
              class="f-regular"
              ref="lastname"
            />
          </b-col>

          <b-col md="6">
            <InputText
              v-model="form.address"
              textFloat="ที่อยู่"
              placeholder="ที่อยู่"
              type="text"
              name="address"
              size="lg"
              isRequired
              :isValidate="$v.form.address.$error"
              :v="$v.form.address"
              class="f-regular"
              ref="address"
            />
          </b-col>

          <b-col md="6">
            <InputText
              v-model="form.road"
              textFloat="ถนน"
              placeholder="ถนน"
              type="text"
              name="road"
              size="lg"
              :v="$v.form.road"
              class="f-regular"
              ref="road"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.subdistrict"
              textFloat="ตำบล/แขวง"
              placeholder="ตำบล/แขวง"
              type="text"
              name="road"
              size="lg"
              :v="$v.form.subdistrict"
              isRequired
              :isValidate="$v.form.subdistrict.$error"
              class="f-regular"
              ref="subdistrict"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.district"
              textFloat="อำเภอ"
              placeholder="อำเภอ"
              type="text"
              name="road"
              size="lg"
              :v="$v.form.district"
              isRequired
              :isValidate="$v.form.district.$error"
              class="f-regular"
              ref="district"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.province"
              textFloat="จังหวัด"
              placeholder="จังหวัด"
              type="text"
              name="road"
              size="lg"
              :v="$v.form.province"
              isRequired
              :isValidate="$v.form.province.$error"
              class="f-regular"
              ref="province"
            />
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              type="tel"
              v-model="form.zipcode"
              name="zipcode"
              isRequired
              :v="$v.form.zipcode"
              :isValidate="$v.form.zipcode.$error"
              :maxlength="5"
              @onkeypress="isNumber"
              size="lg"
              ref="zipcode"
              class="f-regular"
            />
          </b-col>
          <b-col v-if="typeAddress == 'billing'" md="6">
            <InputText
              v-model="form.tax"
              textFloat="เลขประจำตัวผู้เสียภาษี"
              placeholder="เลขประจำตัวผู้เสียภาษี"
              type="text"
              name="taxID"
              size="lg"
              :isRequired="isRequestTaxInvoice"
              :isValidate="$v.form.tax.$error"
              :v="$v.form.tax"
              :maxlength="13"
              @onkeypress="isNumber"
              ref="tax"
            />
          </b-col>
        </b-row>
        <b-row v-if="typeAddress == 'shipping' && !isOrderSendered">
          <b-col class="mt-2 col-12">
            <div class="d-flex">
              <b-form-checkbox
                v-model="inIsSideCheckSameAddress"
                :value="true"
                :unchecked-value="false"
                id="sameReceiptCheckbox"
                name="sameReceiptCheckbox"
                @change="val => $emit('set-change-same-address', val)"
              >
                ใช้ที่อยู่เดียวกับที่อยู่ในการจัดส่ง
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col class="mt-2 col-12">
            <div class="d-flex">
              <b-form-checkbox
                v-model="inIsRequestTaxInvoice"
                :value="true"
                :unchecked-value="false"
                id="getTaxReceiptCheckbox"
                @change="val => $emit('set-change-request-tax-invoice', val)"
              >
                ออกใบกำกับภาษี
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isProfileInfo">
          <b-col class="text-right">
            <b-button variant="danger" @click="hadleEdit(false)"
              >ยกเลิก</b-button
            >
            <b-button variant="success" class="ml-2" @click="handleSaveForm"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </div>
      <b-row
        v-if="
          typeAddress == 'shipping' &&
            !isOrderSendered &&
            !isProfileInfo &&
            !isEdit
        "
      >
        <b-col class="mt-2 col-12">
          <div class="d-flex">
            <b-form-checkbox
              v-model="inIsSideCheckSameAddress"
              :value="true"
              :unchecked-value="false"
              id="sameReceiptCheckbox"
              name="sameReceiptCheckbox"
              @change="val => $emit('set-change-same-address', val)"
            >
              ใช้ที่อยู่เดียวกับที่อยู่ในการจัดส่ง
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col class="mt-2 col-12">
          <div class="d-flex">
            <b-form-checkbox
              v-model="inIsRequestTaxInvoice"
              :value="true"
              :unchecked-value="false"
              id="getTaxReceiptCheckbox"
              @change="val => $emit('set-change-request-tax-invoice', val)"
            >
              ออกใบกำกับภาษี
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col v-if="typeAddress == 'shipping'" md="12" class="pt-3">
          <InputText
            v-model="form.shippingNote"
            textFloat="ข้อความเพิ่มติม"
            placeholder="ข้อความเพิ่มติม"
            type="text"
            name="shippingNote"
            size="lg"
          />
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  required,
  numeric,
  minValue,
  helpers,
  requiredIf
} from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
const valueDefault = (value, flag) => {
  if (value == 0 && flag) {
    return false;
  } else {
    return true;
  }
};
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "ChatOrderProfileAddressForm",
  components: {
    InputText,
    InputSelect
  },
  props: {
    title: {
      required: false,
      type: String
    },
    userInfo: {
      required: false,
      type: Object
    },
    addressInfo: {
      required: false,
      type: Object
    },
    typeAddress: {
      required: false,
      type: String
    },
    sameAddress: {
      required: false,
      type: Boolean
    },
    isRequestTaxInvoice: {
      required: false,
      type: Boolean
    },
    isProfileInfo: {
      required: false,
      type: Boolean
    },
    isOrderSendered: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      isEdit: false,
      form: {
        id: 0,
        name: "",
        userGUID: "",
        defaultBilling: null,
        defaultShipping: null,
        firstname: null,
        lastname: null,
        address: null,
        road: null,
        district: null,
        subdistrict: null,
        province: null,
        zipcode: null,
        taxNo: null,
        zipcodeId: 0,
        nameTitleId: 0,
        shippingNote: ""
      },
      nameTitleArray: [{ id: 0, name: "คำนำหน้าชื่อ" }],
      inIsSideCheckSameAddress: this.sameAddress,
      inIsRequestTaxInvoice: this.isRequestTaxInvoice,

      addressShippingSelectId: 0,
      addressList: []
    };
  },
  computed: {
    titleFirstname() {
      return this.form.nameTitleId == 4 ? "ชื่อบริษัท" : "ชื่อ";
    },
    titleLastname() {
      return this.form.nameTitleId == 4 ? "สาขา" : "นามสกุล";
    }
  },
  methods: {
    hadleEdit(flag) {
      if (flag) {
        this.form = {
          tax: this.addressInfo.taxNo,
          zipcode: this.addressInfo.zipcode,
          ...this.addressInfo
        };
        this.inIsSideCheckSameAddress = this.sameAddress;
        this.inIsRequestTaxInvoice = this.isRequestTaxInvoice;
      } else {
        this.$emit("cancle-edit");
      }
      this.isEdit = flag;
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getNameTitle: async function() {
      let nameTille = this.nameTitleArray;
      let title = null;
      await this.$axios
        .get(`${this.$baseUrlOrder}/api/filter/NameTitle`)
        .then(async response => {
          if (response.data.result == 1) {
            title = response.data.detail;
            this.nameTitleArray = nameTille.concat(title);
          }
        });
    },
    handleSaveForm() {
      if (this.typeAddress == "shipping") {
        this.form.defaultShipping = true;
        this.form.defaultBilling = false;
      }
      if (this.typeAddress == "billing") {
        this.form.defaultShipping = false;
        this.form.defaultBilling = true;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.$emit("handle-save-form", {
        typeAddress: this.typeAddress,
        form: this.form
      });
    },
    handleReturnAddress: async function() {
      if (this.typeAddress == "shipping") {
        this.form.defaultShipping = true;
        this.form.defaultBilling = false;
      }
      if (this.typeAddress == "billing") {
        this.form.defaultShipping = false;
        this.form.defaultBilling = true;
      }
      if (!this.isEdit) {
        return {
          ...this.addressInfo,
          isTrue: true,
          shippingNote: this.form.shippingNote
        };
      } else {
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          for (let key in Object.keys(this.$v.form)) {
            const input = Object.keys(this.$v.form)[key];
            if (input.includes("$")) return false;
            if (this.$v.form[input].$error) {
              this.$refs[input].focus();
            }
          }
          return {};
        } else {
          return { ...this.form, isTrue: true };
        }
      }
    },
    onAddressListSelected(val) {
      const addr = this.addressList.find(it => it.id === val);
      if (this.isEdit) {
        this.form = addr;
      } else {
        this.$emit("handle-set-addres-info", addr);
      }
    }
  },
  created: async function() {
    this.addressList = this.$store.getters.getChatUserAddressList;
    if (this.addressInfo.id) {
      this.addressShippingSelectId = this.addressInfo.id;
    } else {
      if (this.addressList.length > 0) {
        this.addressShippingSelectId = this.$store.getters.getChatUserAddressList[0].id;
      } else {
        this.hadleEdit(true);
      }
    }
    if (this.typeAddress == "billing") {
      await this.getNameTitle();
    }
    if (
      !this.isOrderSendered &&
      !this.isProfileInfo &&
      !this.addressInfo.zipcode
    ) {
      this.hadleEdit(true);
    }
  },
  validations() {
    return {
      form: {
        nameTitleId: {
          required: requiredIf(function() {
            return this.typeAddress == "billing";
          }),
          valueDefault(value) {
            return valueDefault(value, this.typeAddress == "billing");
          }
        },

        firstname: {
          required: requiredIf(function() {
            return this.typeAddress == "billing";
          }),
          alpha
        },
        lastname: {
          required: requiredIf(function() {
            return this.typeAddress == "billing";
          }),
          alpha
        },
        address: { required },
        district: {
          required
        },
        subdistrict: {
          required
        },
        province: {
          required
        },
        zipcode: {
          required,
          numeric,
          minValue: minValue(5)
        },
        tax: {
          required: requiredIf(function() {
            return this.typeAddress == "billing" && this.isRequestTaxInvoice;
          }),
          numeric,
          minValue: minValue(13)
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #eeeeee;
  border: none;
}
::v-deep .btn-danger {
  background-color: #832abf;
  border-color: #832abf;
}
.text-c-red {
  color: #832abf;
}
::v-deep .btn-success {
  background-color: #27c351;
  border-color: #27c351;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #832abf;
  background-color: #832abf;
}
@media (max-width: 767.98px) {
}
</style>
