var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('ChatSideBarSetReply'),(_vm.isShowChat && !(_vm.isNoConversation && _vm.conversationId == ''))?_c('div',{staticClass:"d-flex h-100"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isList || _vm.isShowBoth),expression:"isList || isShowBoth"}],class:['panel-list-chat']},[(_vm.isNoConversation)?_c('div',{staticClass:"h-100 border-right"},[_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ไม่พบรายการแชท")])],1)])]):_c('ChatList',{ref:"ChatList",attrs:{"conversationId":_vm.conversationId},on:{"select-chat":_vm.handleSelectChat,"set-show-title":function (val) { return _vm.$emit('set-show-title', val); },"handle-no-list-chat":function (val) { return (_vm.isNoConversation = val); }}})],1),(!_vm.isList || _vm.isShowBoth)?_c('div',{class:['panel-box-chat']},[(_vm.conversationId != '')?_c('ChatBox',{class:[_vm.isShowBoth ? 'w-50' : 'w-100'],attrs:{"meProfile":_vm.meProfile,"otherProfile":_vm.otherProfile,"isFromList":_vm.isFromList,"isShowBoth":_vm.isShowBoth,"socialType":_vm.socialType,"formatIdConversation":_vm.conversationId,"userId":_vm.selectedUserId,"channelId":_vm.selectedChannelId,"conversationInfo":_vm.conversationInfo,"userChatInfo":_vm.selectedUserChatInfo},on:{"show-list-chat":function () {
            _vm.isList = true;
            _vm.conversationId = '';
          },"set-show-title":function (val) { return _vm.$emit('set-show-title', val); },"reload-list":function($event){return _vm.$refs.ChatList.getChatList(false)},"reload-detail-list":function (val) { return _vm.$refs.ChatList.reloadDetailList(val); },"set-read-conversation":function (val) { return _vm.$refs.ChatList.setReadConversation(val); },"handle-no-list-chat":function (val) { return (_vm.isNoConversation = val); },"set-selected-user-chat":function (val) { return (_vm.selectedUserChatInfo = val); }}}):_vm._e(),(_vm.conversationId == '' && _vm.isShowBoth)?_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ยินดีตอนรับเข้าสู่แชท")])],1)]):_vm._e(),(
          _vm.selectedUserChatInfo.externalId != '' &&
            _vm.selectedUserChatInfo.externalId.length > 0 &&
            _vm.isShowBoth
        )?_c('ChatOrderPanel',{staticClass:"w-50 border-left",attrs:{"userChatInfo":_vm.selectedUserChatInfo,"socialType":_vm.socialType}}):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.isNoConversation && _vm.conversationId == '')?_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ไม่พบรายการแชท")])],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }